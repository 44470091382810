<script lang="ts">
	import { cartItems } from '@stores/cart'
	import { cn } from '@lib/utils'
	export let classes = 'h-4 w-4 text-white'
	export { classes as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 22 14"
	class={cn('h-6 w-6 text-white', classes)}
>
	<path
		id="local_mall_FILL1_wght200_GRAD-25_opsz24"
		d="M166.154-816.77v-10.907H169.3a2.985,2.985,0,0,1,.9-2.192,2.982,2.982,0,0,1,2.192-.9,2.986,2.986,0,0,1,2.193.9,2.984,2.984,0,0,1,.9,2.193h3.142v10.907Zm3.876-10.907h4.72a2.277,2.277,0,0,0-.688-1.671,2.272,2.272,0,0,0-1.671-.689,2.279,2.279,0,0,0-1.672.688A2.274,2.274,0,0,0,170.03-827.677Z"
		transform="translate(-166.154 830.77)"
		fill="#fff"
	/>
</svg>

<script>
	import { isCartOpen, cartItems } from '@stores/cart'
	import Cart from './icons/Cart.svelte'
</script>

<!--use "$" to read the store value-->
<a class="relative flex items-center" href="/cart">
	<span class="hidden md:mr-2 md:inline-flex"> Cart </span>
	<!-- <span
		class="ml-1 flex h-4 w-4 items-center justify-center rounded-full border border-white text-xs"
		>{Object.values($cartItems).length}</span
	> -->
	<Cart class="ml-0" />
	{#if Object.values($cartItems)?.length}
		<span
			class="absolute right-2.5 block h-2.5 w-2.5 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-[#1551E1] md:top-2"
		></span>
	{/if}
</a>
